import React from 'react'
import styles from './LandingHero.module.scss'
import { ButtonMUI } from '../../../../shared'
import { ReactComponent as ConnectWalletIcon } from '../../../../assets/icons/connect-wallet.svg';
import { rootMainPath } from '../../../../routes/paths';
import { sendEvent, classList } from '../../../../helpers/functions';
import { ReactComponent as BottomGold } from './images/bottom_gold.svg'
import { ReactComponent as LeftMan } from './images/left_man.svg'
import { ReactComponent as RightMan } from './images/right_man.svg'
import infoImage from './images/Frame 1948762616.png'

const LandingHero = () => {
    const onClick = () => {
        sendEvent('click_event', {
            'event_name': 'click_join',
        });
    }

    return (
        <div className={styles.root}>
            <BottomGold className={styles.BottomGold} />
            <LeftMan className={styles.LeftMan} />
            <RightMan className={styles.RightMan} />
            <div className={classList("container", styles.container)}>
                <div className={styles.wrapper}>
                    <h1 className={styles.title}>Crypto Chests Presale</h1>
                    <div className={styles.subtitle}>
                        <p>Embark on the 2024 crypto rally with us!</p>
                        <span>10 stages, every stage price increases. Secure your spot now and get a discount up to 80%!</span>
                    </div>
                    <div className={styles.buttons}>
                        <ButtonMUI
                            onClick={onClick}
                            className={styles.button}
                            to={rootMainPath}
                        //startIcon={<ConnectWalletIcon />}
                        >
                            Start playing
                        </ButtonMUI>
                        <a
                            href="https://google.com"
                            target='_blank'
                            className={styles.button}
                        >
                            Join presale
                        </a>
                    </div>
                    <div className={styles.info}>
                        <img src={infoImage} alt="info image" />
                        <p>Experience our live MVP -<br />thousands are already playing</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingHero